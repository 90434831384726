<docs>
Dynamic view component to provide a grid and create/update functionality for
status/type codes and communcations. Which resource is determined by the props
provided by the router.
</docs>

<template>
  <div>
    <AgGridVue
      :style="{ width: 'calc(100vw - 70px)', height: 'calc(100vh - 3.5rem)' }"
      class="ag-theme-balham"
      :grid-options="gridOptions"
      :debug="false"
      @grid-ready="onGridReady"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { AgGridVue } from "ag-grid-vue"
import Swal from "sweetalert2"
import "ag-grid-enterprise"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-balham.css"

import columnDefs from "../components/Grid/resourceColumnDefs"
import ResourceModify from "../components/PanelResourceModify"

export default {
  components: {
    AgGridVue,
    ResourceModify, // eslint-disable-line vue/no-unused-components
  },
  props: {
    resourceType: { required: true, type: String },
  },
  data() {
    return {
      gridOptions: {
        __metadata__: {
          resourceType: this.resourceType,
        },
        rowData: null,
        defaultColDef: {
          sortable: true,
          resizable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            suppressAndOrCondition: true,
            clearButton: true,
          },
          cellStyle: { textAlign: "left" },
          menuTabs: ["filterMenuTab", "generalMenuTab"],
          enableCellChangeFlash: true,
        },
        sideBar: {
          defaultToolPanel: null,
          toolPanels: [
            {
              id: "modify",
              labelDefault: "Modify",
              labelKey: "modify",
              iconKey: "columns",
              toolPanelFramework: "ResourceModify",
            },
          ],
        },
        columnDefs: columnDefs[this.resourceType],
        frameworkComponents: {
          ModifyStatuscodesPanel: "ResourceModify",
        },
      },
    }
  },
  computed: {
    ...mapState(["search", "gridApi"]),
  },
  watch: {
    search(newVal) {
      this.gridApi.setQuickFilter(newVal)
    },
  },
  beforeMount() {
    this.getResource()
  },
  created() {
    window.addEventListener("resize", () => {
      this.gridApi.sizeColumnsToFit()
    })
  },
  destroyed() {
    window.removeEventListener("resize", () => {
      this.gridApi.sizeColumnsToFit()
    })
  },
  methods: {
    ...mapActions(["gridInit"]),
    onGridReady() {
      this.gridInit({
        gridApi: this.gridOptions.api,
        columnApi: this.gridOptions.columnApi,
      })
      this.gridApi.sizeColumnsToFit()
    },
    async getResource() {
      const [error, response] = await this.$api.get(this.resourceType + "/")
      if (error || !Array?.isArray(response.data)) {
        console.error(error || `Expected array got ${typeof response.data}`)
        Swal.fire({
          icon: "error",
          text: error?.toString() || "Unexpected reponse from server.",
        })
        this.gridApi.showNoRowsOverlay()
      } else if (response.data.length < 1) {
        this.gridApi.showNoRowsOverlay()
      } else {
        this.gridApi.hideOverlay()
        this.gridApi.setRowData(response.data)
      }
    },
  },
}
</script>
