<template>
  <div
    class="w-100"
    style="font-size: 1rem; font-family: var(--font-family-sans-serif);"
  >
    <div
      class="card-header sticky-top shadow-sm mb-2 border-right d-flex justify-content-between align-items-center"
    >
      <h4 class="mb-0">
        {{ STATE === STATES.create ? "Create" : "Modify" }}
      </h4>
      <div v-if="resource">
        <PanelControls :show-collapse="false">
          <button
            v-if="resourceType === 'communications' && STATE === STATES.update"
            class="btn btn-sm mr-1 btn-danger"
            @click.prevent="deleteResource"
          >
            <TrashIcon aria-hidden class="feather-sm mr-1"></TrashIcon>
            <strong>Remove</strong>
          </button>
          <button class="btn btn-sm btn-primary mr-1" @click="saveResource">
            <SaveIcon aria-hidden class="feather-sm mr-1"></SaveIcon>
            <strong>Save</strong>
          </button>
        </PanelControls>
      </div>
    </div>

    <ErrorAlert v-if="error" :error="error" @close="error = null"></ErrorAlert>

    <div v-if="!resource" class="text-center my-3">
      Double-click an item to modify it.
    </div>

    <form v-else class="p-2">
      <template v-for="{ field, headerName, __metadata__: meta } in columnDefs">
        <div
          v-if="meta && meta.type === Boolean"
          :key="headerName"
          class="form-check form-check-inline w-100 mb-2"
        >
          <input
            v-if="meta && meta.type === Boolean"
            :id="field"
            v-model="resource[field]"
            :disabled="isUneditable(field)"
            type="checkbox"
            class="form-check-input"
            :aria-describedby="field"
          />
          <label class="form-check-label" :for="field">{{ headerName }}</label>
        </div>
        <div v-else :key="headerName" class="form-group">
          <label :for="field" class="mb-1">{{ headerName }}: </label>
          <textarea
            :id="field"
            v-model="resource[field]"
            :disabled="isUneditable(field)"
            type="text"
            class="form-control"
            :aria-describedby="field"
          />
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import columnDefs from "./Grid/resourceColumnDefs"
import ErrorAlert from "./ErrorAlert"
import PanelControls from "./PanelControls"
import SaveIcon from "../assets/save.svg"
import TrashIcon from "../assets/trash-2.svg"

export default {
  components: {
    ErrorAlert,
    PanelControls,
    SaveIcon,
    TrashIcon,
  },
  data() {
    return {
      STATES: {
        // This component must always be in one of these possible states
        inactive: "inactive",
        create: "create",
        update: "update",
      },
      STATE: "inactive", // Current state of the component

      // Working data
      resourceType: null,
      resource: null,
      rowNode: null,
      error: null,

      // Schema
      columnDefs: null,
    }
  },
  /**
   * Listen to grid events for activation.
   *
   * `createNewResource` is a custom event dispatched by the Create button.
   * */
  mounted() {
    const { gridOptions } = this.params.api.gridOptionsWrapper
    this.resourceType = gridOptions.__metadata__.resourceType
    this.columnDefs = columnDefs[this.resourceType]
    this.params.api.addEventListener("rowDoubleClicked", this.setupResource)
    this.params.api.addEventListener("createResource", this.setupNewResource)
  },
  methods: {
    isUneditable(field) {
      return (
        ["statuscodes", "typecodes"].includes(this.resourceType) &&
        this.STATE === this.STATES.update &&
        field !== "is_active"
      )
    },
    /** Set up for a new resource. */
    setupNewResource() {
      this.resource = {}
      this.STATE = this.STATES.create
      this.params.api.openToolPanel("modify")
    },
    /** Set up for an existing resource. */
    setupResource({ data, node }) {
      this.rowNode = node
      this.resource = data
      this.STATE = this.STATES.update
      this.params.api.openToolPanel("modify")
    },
    /** Create or update the resource. */
    async saveResource() {
      switch (this.STATE) {
        case this.STATES.create:
          await this.createResource()
          break
        case this.STATES.update:
          await this.updateResource()
          break
        default:
          throw new Error("Undefined state")
      }
    },
    /** Create a new resource & refresh the grid. */
    async createResource() {
      const path = `/${this.resourceType}/`
      const [error, response] = await this.$api.post(path, this.resource)
      if (error) {
        this.error = error
      } else {
        this.resource = response.data
        this.STATE = this.STATES.update
        const { add } = this.params.api.updateRowData({
          add: [this.resource],
        })
        this.rowNode = add[0]
        this.params.api.flashCells({ rowNodes: [this.rowNode] })
      }
    },
    /** Update an exisitng resource and its grid row with the response. */
    async updateResource() {
      const path = `/${this.resourceType}/${this.resource.id}/`
      const [error, response] = await this.$api.put(path, this.resource)
      this.error = error
      if (response) {
        this.resource = response.data
        this.rowNode.data = this.resource
        this.params.api.updateRowData({
          update: [this.rowNode.data],
        })
      }
    },
    /** Delete a resource (communications only) */
    async deleteResource() {
      const confirm = await this.$alert.fire({
        title: "Permanently remove communication?",
        icon: "question",
        confirmButtonColor: "#ab0520",
        confirmButtonText: "Remove",
        cancelButtonColor: "#378dbd",
        text: "This action is irreversible.",
        showCancelButton: true,
      })
      if (confirm.value) {
        const path = `/${this.resourceType}/${this.resource.id}/`
        const [error, response] = await this.$api.delete(path)
        this.error = error
        if (response) {
          this.resource = null
          this.STATE = this.STATES.inactive
          this.params.api.updateRowData({ remove: [this.rowNode.data] })
        }
      }
    },
  },
}
</script>

<style></style>
